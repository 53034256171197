#container {
  width: 100%;
  overflow: hidden;
}

#badgeContainer {
  display: table-cell;
  position: absolute;
  margin-left: 220px /*menu width + padding, etc*/;
}

#menu {
  position: fixed;
  width: 200px;
  padding: 5px;
  margin-top: 1em;
}

.menu {
  background-color: #EEEEDD;
  border: 1px solid #CCC;
  border-radius: 3px;
}

.vLine {
  border-left: solid black;
  padding-left: 0.5ch;
  padding-right: 0.5ch;
}

.badge { float: left; }
.draggable { cursor: move; }
.hidden { display: none; }

.closeBtn {
  float: right;
  position: relative;
  right: 25px;
  top: 25px;
}

@media print {
  .no-print, .no-print * { display: none !important; }
  .at-print, .at-print * { display: block !important; }
}

kbd {
  border-radius: 3px;
  padding: 1px 2px 0;
  border: 1px solid black;
}
